.Login {
    padding: 60px 0;
    font-family: 'Open Sans' !important;
}

.Login form {
    margin: 0 auto;
    max-width: 350px;
}

html,body{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
}

.container-login{
    height: 100%;
    align-content: center;
}

.card-login{
    border-radius: 10px;
    height: 420px;
    margin-top: 50px;
    margin-bottom: auto;
    width: 400px;
}

.card-header{
    background-color: transparent;
}

.card-footer{
    margin-top: 20px;
}

.social_icon span{
    font-size: 60px;
    margin-left: 10px;
    color: #FFC312;
}

.social_icon span:hover{
    color: white;
    cursor: pointer;
}

.card-header h3{
    color: white;
}

.social_icon{
    position: absolute;
    right: 20px;
    top: -45px;
}

.input-group-prepend{
    width: 50px;
    background-color: transparent !important;
    border-color:#ced4da;
    color: black;
    border:0 !important;
    margin-right: -4px;
}

.input-group{
    width: 301px;
}

.btn-primary{
background-color: #ee9600ff !important;
border-color: #ee9600ff !important;
}

.remember{
    color: white;
}

.remember input
{
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login_btn{
    color: black;
    background-color: #FFC312;
    width: 100px;
}

.login_btn:hover{
    color: black;
    background-color: white;
}

.links{
    font-weight: bold;
    color: #5d647eff !important;
}

.links button{
    color: #5d647eff;
}
.links a{
    color: #5d647eff;
    font-weight: normal;
}

.login-form{
    border: 50px;
}

.login-form .login-logo {
    padding-bottom: 30px;
    padding-top: 50px;
}

.form-control{
    background-color: #ced4da;
    border-right: 2px #ced4da;
    
}

.input-group-text{
    background-color: transparent !important;
    border: 2px solid #ced4da
}

span img{
    height: 21px;
    width: 20px;
}
